<template>
  <div class="login-page">
    <div class="content-wrap">
      <div
        class="img-wrap"
        data-aos="fade-right"
        data-aos-delay="300"
        data-aos-duration="2000"
      >
        <img src="@/assets/Images/new_login.png" />
      </div>
      <form
        class="form-wrap"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div class="system-name">
          <img src="~@/assets/Images/logo/Logo_02.png" />
          <span>人格特質測驗系統</span>
        </div>
        <div class="field">
          <label>
            <i class="el-icon-office-building"></i>
          </label>
          <select v-model="loginInfo.CompId">
            <option
              v-for="company in companyList"
              :key="company.Guid"
              :value="company.CompanyCode"
            >
              {{ company.CompanyName }}
            </option>
          </select>
        </div>

        <!-- <div class="field">
          <label>
            <i class="el-icon-chat-dot-round"></i>
          </label>
          <select v-model="loginInfo.lang">
            <option value="">中文</option>
            <option
              v-for="(lang, index) of langOptions"
              :key="index"
              :value="lang.Code"
            >
              {{ lang.Name }}
            </option>
          </select>
        </div> -->
        <div class="field">
          <label>
            <i class="el-icon-user"></i>
          </label>

          <input
            v-model.trim="loginInfo.EmpNo"
            type="text"
            placeholder="帳號"
            class="mb-0"
          />
        </div>

        <div class="field">
          <label>
            <i class="el-icon-lock"></i>
          </label>

          <input
            v-model.trim="loginInfo.Password"
            type="password"
            placeholder="密碼"
            class="mb-0"
          />
        </div>
        <button type="button" @click.prevent="login()" class="btn btn-01">登入</button>
        <div class="logo-03-wrap">
          <img class="logo-03" src="~@/assets/Images/logo/Logo_03.png" />
        </div>
      </form>
    </div>

    <!-- 忘記密碼 -->
    <!-- <div
      id="ForgotPassword"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header align-items-center">
              {{ i18n("AdminLogin.ForgetPassword") }}
              <button
                data-dismiss="modal"
                aria-label="Close"
                class="modalClose_Btn"
                type="reset"
              >
                <span style="color: #222">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="field">
                  <label class="required-red">
                    {{ i18n("Basic.Account") }}
                  </label>
                  <input
                    type="text"
                    :placeholder="`${i18n('AdminLogin.Account')}`"
                    v-model="forgetItem.account"
                  />
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="reset"
                class="btn btn_cancel"
                data-dismiss="modal"
                aria-label="Close"
              >
                {{ i18n("Buttons.Close") }}
              </button>
              <button
                class="btn btn_primary"
                type="button"
              >
                {{ i18n("Buttons.Confirm") }}
              </button>
            </div>
        </div>
      </div>
    </div> -->

    <!-- 變更密碼 Modal -->
    <!-- <div
      class="modal fade"
      id="changePasswordModal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <ValidationObserver ref="changePassword" v-slot="{ handleSubmit }">
            <div class="modal-header align-items-center">
              {{ i18n("AdminLogin.ChangePassWord") }}
              <button
                data-dismiss="modal"
                aria-label="Close"
                class="modalClose_Btn"
                type="reset"
              >
                <span style="color: #222">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="field">
                <ValidationProvider
                  name="新密碼"
                  rules="required|minPW:8|regexPW"
                  v-slot="{ errors }"
                  :vid="`${changePassword.password}`"
                >
                  <label class="required-red">
                    {{ i18n("AdminLogin.NewPassword") }}
                    <span class="warning-text small font-weight-bold">
                      ({{ i18n("AdminLogin.NewPasswordText") }})
                    </span>
                  </label>
                  <input
                    type="password"
                    :placeholder="i18n('AdminLogin.NewPassword')"
                    v-model.trim="changePassword.password"
                  />
                  <span v-if="errors[0]" class="text-danger font-weight-bold">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
              <div class="field">
                <ValidationProvider
                  name="密碼"
                  :rules="`confirmed:${changePassword.password}|required`"
                  v-slot="{ errors }"
                >
                  <label class="required-red">
                    {{ i18n("AdminLogin.ConfirmPassword") }}
                  </label>
                  <input
                    type="password"
                    :placeholder="i18n('AdminLogin.ConfirmPassword')"
                    v-model.trim="changePassword.confirmPassword"
                  />
                  <span v-if="errors[0]" class="font-weight-bold text-danger">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
              <div class="field">
                <ValidationProvider
                  name="驗證碼"
                  rules="required|alpha_num"
                  v-slot="{ errors }"
                >
                  <label class="required-red">
                    {{ i18n("AdminLogin.VerificationCode") }}
                    <span class="warning-text small font-weight-bold">
                      ({{ i18n("AdminLogin.VerificationCodeText") }})
                    </span>
                  </label>
                  <input
                    type="text"
                    :placeholder="i18n('AdminLogin.VerificationCode')"
                    v-model.trim="changePassword.code"
                  />
                  <span v-if="errors[0]" class="font-weight-bold text-danger">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
            </div>
            <div class="modal-footer">
              <button type="reset" class="btn btn_cancel" data-dismiss="modal">
                {{ i18n("Buttons.Close") }}
              </button>
              <button
                type="button"
                class="btn btn_primary"
                @click="handleSubmit(changePW)"
              >
                {{ i18n("Buttons.Confirm") }}
              </button>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div> -->
  </div>
  <!-- <div class="login-page">
    <div class="content-wrap">
      <div class="system-name">
        <span>人格特質測驗系統</span>
      </div>
      <form @submit.prevent="login" class="form-wrap">
        <label> COMPANY </label>
        <select v-model="loginInfo.CompId">
          <option>遠東新世紀股份有限公司</option>
          <option
            v-for="comp in company"
            :key="comp.Guid"
            :value="comp.CompanyCode"
          >
            {{ comp.CompanyName }}
          </option>
        </select>
        <label> ACCOUNT </label>
        <input
          type="type"
          placeholder="請輸入員工編號"
          name="name"
          v-model.trim="loginInfo.account"
          required
        />

        <label> PASSWORD </label>
        <input
          type="password"
          placeholder="請輸入密碼"
          name="name"
          v-model.trim="loginInfo.password"
          @keyup.enter="login"
          required
        />

        <div class="button-wrap right">
          <router-link
            class="btn btn-01"
            to="/Start"
            style="text-decoration: none"
          >
            登入
          </router-link>
        </div>

        <div class="contact" v-if="contact !== null">
          <p>{{ contact.Message1 }}</p>
          <p>{{ contact.Message2 }}</p>
        </div>
      </form>
    </div>
  </div> -->
</template>

<style lang="scss">
body {
  margin: 0;
}
.login-page {
  height: 100vh;
  background: #2f75d1;
  background: linear-gradient(336deg, #2f75d1 35%, #70c5d2 100%);
    overflow: auto;

  .content-wrap {
    @media only screen and (min-width: 768px) {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      width: 90%;
      max-width: 960px;
      // align-items: center;
    }

    @media only screen and (max-width: 767px) {
      display: block;
      width: 100%;
      height: 100vh;
      margin: 0 auto;
      padding: 10px;
    }
    .img-wrap {
      width: 50%;
      margin-right: 40px;
      margin-top: 5%;
      @media only screen and (max-width: 767px) {
        display: none;
      }
      img {
        width: 100%;
      }
    }

    .form-wrap {
      flex: 1;
      background-color: #fff;
      background: linear-gradient(336deg, #aed6e2 35%, #ffffff 100%);
      padding: 40px;
      box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.25);
      padding-bottom: 0;
      border-radius: 10px;

      .system-name {
        text-align: center;
        margin-bottom: 25px;
        img {
          display: block;
          margin: 0 auto;
          width: 270px;
          max-width: 95%;
        }
        span {
          background-color: #1a4186;
          color: #fff;
          padding: 3px 20px;
          border-radius: 5px;
        }
      }

      .memo {
        color: #6c757d !important;
        margin-top: 15px;
      }

      .logo-03 {
        position: relative;
        left: -10px;
        height: 80px;
        // margin-top: 15px;
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active,
      select:-webkit-autofill,
      select:-webkit-autofill:hover,
      select:-webkit-autofill:focus,
      select:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #fff inset !important;
      }

      .field {
        background-color: #fff;
        border-radius: 25px;
        display: flex;
        padding: 5px;
        margin-top: 15px;
        font-size: 1em;
        label {
          background-color: #1a4186;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          text-align: center;
          line-height: 30px;
          color: #fff;
          margin-bottom: 0;
          i {
            position: relative;
            top: 2px;
          }
        }
        input,
        select {
          border: none;
          background-color: #fff !important;
          line-height: 30px;
          height: 30px;
          flex: 1;
          padding-left: 15px;

          &:active,
          &:focus {
            border: none;
            outline: none;
          }
        }
      }

      .btn {
        width: 100%;
        margin-top: 20px;
        font-size: 16px;
        border-radius: 25px;
        background-color: #1a4186;
        color: #fff;

        &:hover {
          background-color: darken(#1a4186, 10%);
        }
        // background-color: #337bbd;
        // &:hover{
        //   background-color: darken(#337bbd,10%);
        // }
      }

      @media only screen and (max-width: 767px) {
        height: 100%;
        padding: 30px;
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
}
</style>

<script>
import jwtDecode from 'jwt-decode';

export default {
  data() {
    return {
      screen: null, // loading遮罩
      loginInfo: {
        CompId: null,
      },
      companyList: [],
    };
  },
  methods: {
    login() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/auth/login`;

      const params = {
        CompId: this.loginInfo.CompId,
        EmpNo: this.loginInfo.EmpNo,
        Password: this.loginInfo.Password,
      };

      this.$http
        .post(url, params)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: '錯誤',
              message: response.data.ErrorMessage,
              type: 'error',
              duration: 0,
            });
          } else {
            const decoded = jwtDecode(response.data.Data.JwtToken);
            console.log('decoded', decoded);
            localStorage.setItem('EmpCName', decoded.EmpCName);
            localStorage.setItem('TitleName', decoded.TitleName);
            localStorage.setItem('Name', decoded.Name);
            localStorage.setItem('UserId', decoded.UserId);
            localStorage.setItem('UserName', decoded.UserName);
            localStorage.setItem('CompId', decoded.CompId);
            localStorage.setItem('DeptId', decoded.DeptId);
            localStorage.setItem('EmpNo', decoded.EmpNo);
            localStorage.setItem('IsAD', decoded.IsAD);
            localStorage.setItem('RoleId', decoded.RoleIds[0]);
            localStorage.setItem('RoleName', decoded.roles.toString());

            this.$cookies.set('token', response.data.Data.JwtToken);
            // const token = `${response.data.Data.JwtToken}`;
            // const expired = decoded.exp;
            // document.cookie = `DdmcToken=Bearer ${token}; expires=${new Date(
            //   expired * 1000,
            // ).toUTCString()};`;
            this.screen.close();
            // this.$notify({
            //   message: '已成功登入',
            //   type: 'success',
            // });

            if (response.data.Data.IsWaitingEvaluation) {
              this.$router.push({ path: 'Question' });
            } else {
              this.$router.push({ path: 'home' });
            }
          }
          this.screen.close();
        })
        .catch(() => {
          this.$notify({
            title: '錯誤',
            message: '帳號/密碼錯誤',
            type: 'error',
            duration: 0,
          });
          this.screen.close();
        });
    },

    getCompany() {
      const url = `${window.BaseUrl.api}/auth/CompanyRange`;

      this.$http.get(url).then((response) => {
        if (response.data.ErrorMessage) {
          this.$notify({
            title: '錯誤',
            message: response.data.ErrorMessage,
            type: 'error',
            duration: 0,
          });
        } else {
          this.companyList = response.data.Data;
          this.loginInfo.CompId = this.companyList[0].CompanyCode;
        }
      });
    },
  },
  created() {
    this.getCompany();
  },
};
</script>
